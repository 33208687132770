<template>
    <div class="page">
        <div class="main">
            <div>

                <van-tabs :active="search.status" @change="onChange">
                    <van-tab title="全部" :name="-10"></van-tab>
                    <van-tab title="已接收" :name="0"></van-tab>
                    <van-tab title="送评中" :name="1"></van-tab>
                    <van-tab title="评级中" :name="2"></van-tab>
                    <van-tab title="已付款" :name="3"></van-tab>
                    <van-tab title="已返还" :name="4"></van-tab>
                </van-tabs>
            </div>
            <div class="no" v-if="list.length == 0">
                <div class="list4">
                </div>
                <p style="text-align: center">未查询到相关记录</p>
                <!-- <p>
            <router-link to="/service/1">查看说明</router-link>
          </p> -->
            </div>
            <div class="list4" v-else>
                <div class="item" v-for="(item, index) in list" :key="index" @click="goto(item.id)">
                    <div class="row">
                        <div class="title">No. {{ item.order_sn }}</div>
                        <div class="after" :style="{ color: item.statusObj.color }">
                            <b>
                                <template v-if="item.statusObj.name == '已评级'">评级中</template>
                                <template v-else>{{ item.statusObj.name }}</template>
                            </b>
                        </div>
                    </div>
                    <!-- <div class="row">
              <div class="left">{{item.service}} - {{item.type}}</div>
            </div> -->
                    <div class="row">
                        <div class="col" style="flex: 1; min-width: 0;">
                            <div class="row">
                                <div class="left">送评数量：{{ item.amount }}</div>
                            </div>
                            <div class="row">
                                <div class="left">接收日期：{{ item.created_at }}</div>
                            </div>
                            <div class="row">
                                <div class="left">返还日期：{{ item.express_at }}</div>
                            </div>
                            <div class="row">
                                <div class="left" style="width: 100%; word-break: break-word;">备注：{{ item.desc }}</div>
                            </div>
                        </div>
                        <img v-if="item.gs" style="width: 60px; align-self: center;" :src="item.gs" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Clipboard from 'clipboard';
import { Notify } from 'vant';
import { Form, Field, RadioGroup, Radio, Tabs, Tab } from "vant";

export default {
  components: {
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
  },
    data() {
        return {
            shop_id: null,
            list: [],
            search: {
                status: -10
            },
            headAmount: {
                yijieshouAmount: 0,
                songpingzhongAmount: 0,
                yipingjiAmount: 0,
                yifukuanAmount: 0,
                yifanhuanAmount: 0,
            }
        };
    },

    async created() {
        this.shop_id = this.$route.params.shop_id || 1;
        this.search.status = this.$route.query.status || -10;
        this.search.status = Number(this.search.status)
        this.getList()
    },
    async mounted() {
        localStorage.setItem("backTo", this.$route.fullPath);
        if (!this.shop_id) return this.$router.push("/noshop");

        const r = await this.$axios.get("/shop/index?shop_id=" + this.shop_id);
        if (r.status == 1) {
            this.shop = r.shop;
        }

        const that = this;
        // --------------- 获取signature ---------------
        const fullPath = this.$config.domain + this.$route.fullPath;

        this.signature = await this.$axios.post("/getSignature", {
            url: fullPath,
        });

        wx.config({
            debug: false,
            appId: this.signature.appId,
            timestamp: Number(this.signature.timestamp),
            nonceStr: this.signature.nonceStr,
            signature: this.signature.signature,
            jsApiList: ["updateTimelineShareData", "updateAppMessageShareData"],
        });

        wx.ready(function () {
            //--------------- 分享 ---------------
            wx.updateAppMessageShareData(
                {
                    title: that.shop.shop_name + " -- 送评助手", // 分享标题
                    desc: '上海辉龙阁实体店入驻上海云洲商厦，距离PCGS.NGC.公博.华夏上海办公室仅三余公里，每周两次送评及取币；客户送评，按币状态做出最佳分类，专业高效值得信赖。', // 分享描述
                    link: fullPath, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                    imgUrl: that.shop.logo, // 分享图标
                },
                function (res) {
                    console.log(res, 11122);
                    //这里是回调函数
                }
            );

            wx.updateTimelineShareData(
                {
                    title: that.shop.shop_name + " -- 评级服务", // 分享标题
                    link: fullPath, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                    imgUrl: that.shop.logo, // 分享图标
                },
                function (res) {
                    //这里是回调函数
                }
            );
        });
        wx.error(function (res) {
            console.error(res, "---------------错误信息---------------");
        });
    },
    methods: {
        onChange(e) {
            this.search.status = e
            this.getList()
        },
        async getList() {
            const r = await this.$axios.get('/service/getMyList?shop_id=' + this.shop_id + '&status=' + this.search.status);
            if (r.status == 1) {
                for (let item of r.list) {
                    if (item.pingji_jigou == 'PCGS') {
                        item.gs = '/pcgs.png'
                    }
                    else if (item.pingji_jigou == '公博') {
                        item.gs = '/gongbo.png'
                    }
                    else if (item.pingji_jigou == 'NGC') {
                        item.gs = '/ngc.png'
                    }
                    else {
                        item.gs = ''
                    }

                    if (!item.statusObj) {
                        item.statusObj = {
                            color: '#555',
                            name: '未知'
                        }
                    }
                }
                this.list = r.list
            }
        },
        copy() {
            let that = this;
            let e = new Clipboard(".copy", {
                target: function (trigger) {
                    return document.getElementById("text");
                }
            });
            e.on("success", function (t) {
                t.clearSelection();

                Notify({ type: 'success', message: '复制成功' });
            });
        },
        goto(id) {
            console.log(id);
            this.$router.push('/service/item/' + this.shop_id + '?id=' + id)
        },
        goto2() {
            this.$router.push('/service/desc/' + this.shop_id)
        }
    },
};
</script>

<style lang='scss' scord>
.imgs {
    background: #fff;
    padding: 0 30px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #f5f5f5;

    img {
        width: 60px;
    }
}

.title2 {
    padding-bottom: 10px;
    font-weight: 500;
    color: #E02F21;
    font-size: 18px;
}

.no {
    margin: 20px 0;
    // text-align: center;
}

.page {
    background: #f5f5f5;
}

.sec {
    margin: 15px;
    background: #fff;
    padding: 15px;
    border-radius: 3px;

    .title3 {
        font-size: 18px;
        font-weight: 400;
        margin: 10px 0 15px 0;
    }

    .copy {
        float: right;
    }

    ul {
        list-style-type: circle !important;
        padding-left: 30px !important;
    }

    .border {
        background: #f5f5f5;
        border-radius: 3px;
        padding: 10px;
        line-height: 24px;

        button {
            margin-left: 10px;
            background: #F5C837;
            border: none;
            font-size: 12px;
            border-radius: 3px;
        }
    }
}

.btn {
    margin: 15px;
    // position: fixed;
    // bottom: 90px;
    // left: 0;

    button {
        background-color: #F5C837;
        display: block;
        margin: 0 auto;
        width: 100%;
        height: 44px;
        border: none;
        border-radius: 5px;
        font-size: 16px;
        color: #000;
    }
}

.wode-songpingdan {
    margin: 15px;
    background: #fff;
    padding: 15px;
    border-radius: 3px;

    .ws-top {
        font-size: 18px;
        font-weight: 400;
        display: flex;

        .ws-title {
            flex: 1;
            display: flex;
            align-items: center;
        }

        .jiantou-right {
            display: flex;
            font-size: 14px;
            align-items: center;
        }
    }

    .songpingdans {
        margin-top: 10px;
        display: flex;

        .row {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;

            img {
                width: 26px;
                margin-bottom: 5px;
            }

            .img-title {
                font-size: 14px;
            }

            .amount {
                position: absolute;
                right: 11px;
                top: -9px;
                color: red;
                font-weight: 500;
            }
        }
    }
}
</style>